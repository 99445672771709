<template>
	<div class="cbox">
		<div class="headbar">
			<div>
				<el-form :model="queryForm" style="">
					<el-form-item label="关键字" min-width="120">
						<el-input v-model="searchForm.keyword" size="small" placeholder="请输入查询关键字"
							style="max-width: 200px;margin-right: 10px; margin-left: 10px;" />

						<el-button size="small" icon="el-icon-search" @click="getList" plain
							type="primary">查询</el-button>

					</el-form-item>

				</el-form>
			</div>
		</div>



		<div class="bcontent">
			<el-table stripe :data="tableData" size="mini">

				<el-table-column prop="cdate" width="240" label="日志时间">
				</el-table-column>
				<el-table-column prop="log_type" label="日志类型" width="100"></el-table-column>
				<el-table-column prop="log_content" label="日志内容" min-width="120"></el-table-column>


			</el-table>
			<div style="height: 20px;">

			</div>


		</div>
		<div
			style="position: absolute; bottom: 0; height: 40px; width: 100%; text-align: right; background-color: #fff; z-index: 20;">

			<el-pagination @current-change="handlePageChange" :current-page="page.pageIndex" :page-size="page.pageSize"
				:total="page.total" :page-sizes="page.pageSizes" layout="total, prev, pager, next, jumper">
			</el-pagination>
		</div>

	</div>


</template>

<script>

export default {
	data() {
		return {
			searchForm: {
				app_name: "zcgl-ngz",
				keyword: "",
				ktype: "log_content"
			},
			tableData: [],
			page: {
				pageIndex: 1,
				pageSize: 20,
				pageSizes: [10, 20, 50],
				total: 0
			},
		}
	},


	mounted() {
		this.getList()
	},
	methods: {
		getList() {
			this.searchForm.page = this.page.pageIndex;
			//http://mmnet.oicp.vip
			this.$http.post("https://log.aiwx.org.cn/logs", this.searchForm).then(res => {
				this.tableData = res.data.data;
				this.page = {
					pageIndex: res.data.page.current_page,
					pageSize: res.data.page.per_page,
					pageSizes: [10, 20, 50],
					total: res.data.page.count
				}
			})

		},
		handlePageChange(e) {
			this.page.pageIndex = e
			this.getList()
		},

	}
}

</script>

<style></style>
